const vehicleStatusMapping = {
	'neuf': 'New',
	'd\'occasion': 'Used'
};
const vehicleFuelTypeMapping = {
	'Diésel': 'Diesel',
	'Essence': 'Gasoline',
	'Électrique': 'Electric',
	'Hybride': 'Hybrid'
};
const prefContactMapping = {
	'Appel': 'phone',
	'Courriel': 'email',
	'Texto': 'text'
};
let utilities = {
	translatetoEnglishForShiftDigital( value, vehicleDetailCategory ) {
		let mapping;
		switch ( vehicleDetailCategory ) {
		case 'vehicleStatus':
			mapping = vehicleStatusMapping;
			break;
		case 'vehicleFuelType' :
			mapping = vehicleFuelTypeMapping;
			break;
		case 'prefContact':
			mapping = prefContactMapping;
			break;
		default:
			return value;
		}
		return mapping[value] || value;
	},
	ajaxVehicles( callback, query = {}) {

		let vmsApiEndpoint = globalVars.vmsApiUrl;

		if ( query.vn ) {
			vmsApiEndpoint += `inventory/${ globalVars.inventoryId }/?`;
		} else {
			vmsApiEndpoint += `filtering/?cp=${ globalVars.inventoryId }`;
		}

		vmsApiEndpoint += !! globalVars.useSearchModel ? '&sf=true' : '';
		vmsApiEndpoint += `&ln=${ globalVars.language }&`;

		if ( ! query.vn ) {

			let queryArr = [];

			for ( let key in query ) {
				if ( query.hasOwnProperty( key ) ) {
					queryArr.push( encodeURIComponent( key ) + '=' + encodeURIComponent( query[key]).replace( /%25/g, '%' ) );
				}
			}

			if ( 'true' === globalVars.advancedPricing ) {
				for ( let key in globalVars.pricingSettings ) {
					if ( globalVars.pricingSettings.hasOwnProperty( key ) ) {
						queryArr.push( encodeURIComponent( key ) + '=' + encodeURIComponent( globalVars.pricingSettings[key]) );
					}
				}
			}

			vmsApiEndpoint += queryArr.join( '&' );
		} else {
			vmsApiEndpoint += `vn=${query.vn}&ba=true`;
		}

		if ( 'true' === globalVars.hideZeroPriceVehicles ) {
			vmsApiEndpoint += '&hzpv=true';
		}

		if ( globalVars.inventoryTags && ( ! ( 'tg' in query ) || '' === query.tg ) ) {
			const tagParams = [];
			tagParams.push( 'tg=' + globalVars.inventoryTags );
			tagParams.push( 'tgm=' + globalVars.inventoryTagsMethod );
			tagParams.push( 'tgsc=' + globalVars.inventoryTagsSaleClass );

			vmsApiEndpoint += `&${tagParams.join( '&' )}`;
		}

		let vmsApiURI = '?endpoint=' + encodeURIComponent( vmsApiEndpoint ) + '&action=vms_data';

		// AJAX - GET Request to ajax-vehicles.php / VMS API.
		let xhr = new XMLHttpRequest();
		xhr.open( 'GET', `${ globalVars.pluginsUrl }/convertus-vms/include/php/ajax-vehicles.php${ vmsApiURI }`, true );
		xhr.onload = () => {
			if ( 200 === xhr.status ) {
				let response;
				try {
					response = JSON.parse( xhr.response );
				} catch ( e ) {
					response = xhr.response;
				}
				callback( response );
			} else {
				console.error( 'Request failed.  Returned status of ' + xhr.status );
			}
		};

		xhr.send();
	},

	ajaxSimilar( callback, query = {}) {
		let xhr = new XMLHttpRequest();
		xhr.open( 'POST', globalVars.ajaxUrl );
		xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
		xhr.onload = () => {
			if ( 200 === xhr.status ) {
				callback( JSON.parse( xhr.response ) );
			} else {
				console.error( 'Request failed.  Returned status of ' + xhr.status );
			}
		};
		let params = [ 'action=vms_similar&nonce=' + vmsNonce.nonce ];
		for ( let key in query ) {
			params.push( key + '=' + query[key]);
		}
		if ( 'true' === globalVars.advancedPricing ) {
			for ( let key in globalVars.pricingSettings ) {
				if ( globalVars.pricingSettings.hasOwnProperty( key ) ) {
					params.push( encodeURIComponent( key ) + '=' + encodeURIComponent( globalVars.pricingSettings[key]) );
				}
			}
		}
		if ( 'true' === globalVars.hideZeroPriceVehicles ) {
			params.push( 'hzpv=true' );
		}

		if ( globalVars.inventoryTags ) {
			params.push( 'tg=' + globalVars.inventoryTags );
			params.push( 'tgm=' + globalVars.inventoryTagsMethod );
			params.push( 'tgsc=' + globalVars.inventoryTagsSaleClass );
		}

		xhr.send( encodeURI( params.join( '&' ) ) );
	},

	// Get siblings of element passed
	// Pass needle to get specific sibling
	getSiblings( el, needle ) {
		let result = [];

		for ( let node = el.parentNode.firstElementChild; node; node = node.nextSibling ) {
			if ( 1 === node.nodeType && node != el ) {
				if ( needle && node.matches( needle ) ) {
					result.push( node );
				} else if ( needle && ! node.matches( needle ) ) {
					continue;
				} else {
					result.push( node );
				}
			}
		}
		result = Array.prototype.slice.call( result );
		return result;
	},

	// Get chidlren of element passed
	// Pass needle to get specific child
	getChildren( el, needle ) {
		if ( el.hasChildNodes() ) {
			let children = el.childNodes;
			if ( ! needle ) {
				return children;
			} else {
				let child = null;
				for ( let i = 0; i < children.length; i++ ) {
					if ( 1 === children[i].nodeType && children[i].matches( needle ) ) {
						child = children[i];
						break;
					}
				}
				return child;
			}

		}
	},

	getClosest( el, selector ) {
		for ( ; el && el !== document; el = el.parentNode ) {
			if ( el.matches( selector ) ) {
				return el;
			}
		}
		return null;
	},

	addListener( el, listener, callback, delegation ) {
		if ( delegation ) {
			document.addEventListener( listener, e => {
				if ( utilities.getClosest( e.target, el ) ) {
					callback( e );
				}
			});
		} else {
			for ( let i = 0; i < el.length; i++ ) {
				el[i].addEventListener( listener, callback );
			}
		}
	},

	formatCommas( num, dollar, email = false ) {

		// Round to 2 decimals if it has decimals.
		if ( 'number' ===  typeof num && Math.floor( num ) !== num ) {
			num = Math.floor( num );
		}

		let parts = num.toString().split( '.' );
		let seperator = ',';

		let dollarClass = 'convertus-dollar-sign';

		if ( 'true' === globalVars.superscriptDollarSign ) {
			dollarClass = `${dollarClass} sup`;
		} else if ( 'true' === globalVars.subscriptDollarSign ) {
			dollarClass = `${dollarClass} sub`;
		}

		let dollarHtml = `<span class="${dollarClass}">$</span>`;

		// Remove span tags for prices in email template.
		if ( email ) {
			dollarHtml = '$';
		}

		if ( 'fr' === globalVars.language ) {
			seperator = ' ';
		}
		parts[0] = parts[0].replace( /\B(?=(\d{3})+(?!\d))/g, seperator );
		let html = '';
		if ( 'fr' !== globalVars.language ) {
			html += dollar ? dollarHtml : '';
			html += parts.join( '.' );
		}

		if ( 'fr' === globalVars.language ) {
			html += parts.join( ',' );
			html += dollar ? dollarHtml : '';
		}
		return html;
	},

	formatPhoneNumber( num ) {
		if ( 10 === num.length ) {
			let formatted = num.replace( /[^0-9]/g, '' );
			return formatted.replace( /(\d{3})(\d{3})(\d{4})/, '($1)-$2-$3' );
		} else if ( 11 === num.length ) {
			let formatted = num.replace( /[^0-9]/g, '' );
			return formatted.replace( /(\d{1})(\d{3})(\d{3})(\d{4})/, '$1-($2)-$3-$4' );
		}
		return num;
	},

	getUrlParameter( param ) {
		param = param.replace( /[\[]/, '\\[' ).replace( /[\]]/, '\\]' );
		let regex = new RegExp( '[\\?&]' + param + '=([^&#]*)' );
		let results = regex.exec( location.search );
		return null === results ? '' : decodeURIComponent( results[1].replace( /\+/g, ' ' ) );
	},

	isVehicleNew( vehicle ) {
		if ( vehicle ) {
			if ( 'new' === vehicle.sale_class.toLowerCase() || 'neuf' === vehicle.sale_class.toLowerCase() ) {
				return true;
			}
		}
		return false;
	},

	getPriceDisclaimer( vehicle ) {
		if ( utilities.isVehicleNew( vehicle ) ) {
			if ( vmsData.settings.srpNewDisclaimer ) {
				return vmsData.settings.srpNewDisclaimer;
			} else {
				return translatedStrings.vehicleCard.disclaimer;
			}
		} else {
			if ( vmsData.settings.srpUsedDisclaimer ) {
				return vmsData.settings.srpUsedDisclaimer;
			} else {
				return translatedStrings.vehicleCard.disclaimer;
			}
		}
	},

	getLuxuryTaxDisclaimer() {
		return translatedStrings.vehicleCard.luxuryTaxDisclaimer;
	},

	ajaxCall( url, callMethod = 'GET', callData = {}, callback ) {
		let queryString = [];
		for ( let key in callData ) {
			queryString.push( key + '=' + callData[key]);
		}
		queryString = queryString.join( '&' );

		if ( false || !! document.documentMode || /Edge/.test( navigator.userAgent ) ) {

			if ( 'GET' === callMethod ) {
				url += queryString ? '?' + queryString : '';
				queryString = '';
			}

			const xhr = new XMLHttpRequest();
			xhr.open( callMethod, url, true );
			xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
			xhr.responseType = 'json';
			xhr.onload = () => {
				if ( 4 == xhr.readyState && 200 == xhr.status ) {
					let data = {};
					if ( 'object' !== typeof xhr.response ) {
						data = JSON.parse( xhr.response );
					} else {
						data = xhr.response;
					}
					callback( null, data );
				} else {
					callback( status, xhr.response );
				}
			};
			xhr.send( encodeURI( queryString ) );
		} else {
			if ( 'GET' === callMethod ) {
				url += queryString ? '?' + queryString : '';
				fetch( url )
					.then( res => {
						return res.ok ? Promise.resolve( res ) : Promise.reject( new Error( 'Failed to load' ) );
					})
					.then( res => res.json() )
					.then( data => callback( null, data ) )
					.catch( err => callback( status, err ) );
				return;
			} else {
				fetch( url, {
					method: callMethod,
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					body: 0 < queryString.length ? queryString : undefined
				}).then( res => {
					return res.ok ? Promise.resolve( res ) : Promise.reject( new Error( 'Failed to load' ) );
				})
					.then( res => res.json() )
					.then( data => callback( null, data ) )
					.catch( err => callback( status, err ) );
			}
		}
	},

	ajaxCarfax( action, callback, query = {}) {
		let xhr = new XMLHttpRequest();
		xhr.open( 'POST', globalVars.ajaxUrl );
		xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
		xhr.onload = () => {
			if ( 200 === xhr.status ) {
				callback( xhr.response );
			} else {
				console.error( 'Request failed.  Returned status of ' + xhr.status );
			}
		};
		let params = [ `action=${action}&nonce=${vmsNonce.nonce}&Language=${globalVars.language}` ];
		for ( let key in query ) {
			params.push( key + '=' + query[key]);
		}
		xhr.send( encodeURI( params.join( '&' ) ) );
	},


	ajaxSingleVehicle( callback, query = {}) {
		let xhr = new XMLHttpRequest();
		xhr.open( 'POST', globalVars.ajaxUrl );
		xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
		xhr.onload = () => {
			if ( 200 === xhr.status ) {
				callback( JSON.parse( xhr.response ) );
			} else {
				console.error( 'Request failed.  Returned status of ' + xhr.status );
			}
		};
		let params = [ 'action=vms_garage_vehicle&nonce=' + vmsNonce.nonce + '&language=' + globalVars.language ];
		for ( let key in query ) {
			params.push( key + '=' + query[key]);
		}

		xhr.send( encodeURI( params.join( '&' ) ) );
	},

	calculatePayment( price, apr, down, frequency, term, getMaxPrice ) {
		apr = Number( apr );
		term = Number( term );
		let result  = 0;
		let formula = ( ( ( ( apr / 100 ) / frequency ) * Math.pow( ( 1 + ( apr / 100 ) / frequency ), ( term / 12 * frequency ) ) ) / ( Math.pow( ( 1 + ( apr / 100 ) / frequency ), ( term / 12 * frequency ) ) - 1 ) );

		if ( getMaxPrice ) {
			result = Math.round( ( price - down )  * formula );
		} else {
			result = Math.round( price / formula + down );
		}
		if ( 0 > result ) {
			return 0;
		} else {
			return result;
		}
	},

	calculateLeasePayment( price, apr, downPayment, frequency, term, residualPerctentage ) {
		let residualValue = Math.round( price * residualPerctentage / 100 );
		let netCapitalizedCost = price - downPayment;
		let depreciationFee = ( netCapitalizedCost - residualValue ) / ( term / 12 * frequency );
		let moneyFactor = apr / 2400 * 12 / frequency;
		let financingFee = ( netCapitalizedCost + residualValue ) * moneyFactor;
		let leasePayment = Math.round( financingFee + depreciationFee );

		if ( 0 > leasePayment ) {
			return 0;
		} else {
			return leasePayment;
		}
	},

	calculateAdditionToPrice( model, trim, colorCode = '' ) {
		let addDestinationFee    = inventorySummary.settings.addDestinationFee;
		let additionalFeesToggle = inventorySummary.settings.additionalFeesToggle;
		let additionalFeesConfig = inventorySummary.settings.additionalFeesConfig;

		let additionToPrice = 0;
		if ( 'true' === addDestinationFee && trim && trim.destination ) {
			additionToPrice = additionToPrice + trim.destination;
		}
		if ( 'true' === addDestinationFee && model && model.destination ) {
			additionToPrice = additionToPrice + model.destination;
		}
		if ( 'true' === additionalFeesToggle ) {
			let fees = 0;

			let additionalFees = null;
			if ( '1' === globalVars.additionalFeeYMM ) {
				additionalFees = Object.values( additionalFeesConfig ).filter( fee => utilities.shoudlApplyAdditionalFee( fee, model, trim ) );
			} else {
				additionalFees = Object.values( additionalFeesConfig ).filter( fee => fee.active &&  0 < parseInt( fee.value ) );
			}

			if ( additionalFees ) {
				let values = additionalFees.map( fee => parseInt( fee.value ) );
				fees = values.reduce( ( a, b ) => a + b, 0 );
			}
			additionToPrice = additionToPrice + fees;
		}
		if ( colorCode ) {
			let colorFee = trim && trim.options && trim.options[colorCode] && trim.options[colorCode].msrp_min ? trim.options[colorCode].msrp_min : 0;
			additionToPrice = additionToPrice + colorFee;
		}
		return additionToPrice;
	},

	shoudlApplyAdditionalFee( fee, model, trim = {}) {
		if ( ! fee.active ) {
			return false;
		}

		if ( 0 >= parseInt( fee.value ) ) {
			return false;
		}

		let isYearsSelected = 0 < fee?.years?.length;
		let isMakesSelected = 0 < fee?.makes?.length;
		let isModelsSelected = 0 < fee?.models?.length;

		let currentModel = ( model?.year && model?.make ) ? model : trim; // either one of the object should be set

		if ( currentModel && currentModel?.year && currentModel?.model ) {

			if ( isModelsSelected  ) {

				// in case of model listing page, id is usually set and it looks like eg: 2024-Civic
				if ( currentModel?.id ) {
					return fee?.models?.includes( currentModel.id );
				} else {

					/**
					* If page is model details, then id is not set
					* model names are different in EN and FR. And we are saving EN names while saving additional fee with selected models.
					* So we cannot use currentModel.model directly because if could have FR model name which will mismatch with additional fee models
					* We will try to extract the model name from URL and it is always in EN regardless of langauge
					*/
					let url_path_arr = decodeURIComponent( window.location.pathname ) || '';
					url_path_arr = url_path_arr?.split( '/' );
					url_path_arr = url_path_arr.map( path_item => `${currentModel?.year}-${path_item}` ); // we saved in DB like 2024-Civic
					let model_found = fee?.models?.filter( model_name => url_path_arr.includes( model_name ) );
					return model_found?.length;
				}

			}

			if ( isYearsSelected && ! isMakesSelected && ! isModelsSelected ) {
				return fee?.years?.includes( currentModel?.year?.toString() );
			}

			if ( ! isYearsSelected && isMakesSelected && ! isModelsSelected ) {
				return fee?.makes?.includes( currentModel?.make );
			}

			if ( isYearsSelected && isMakesSelected && ! isModelsSelected ) {
				return fee?.years?.includes( currentModel?.year?.toString() ) && fee?.makes?.includes( currentModel?.make );
			}

			return true; // we were able to identify the model, but none of the inclusion condition selected, so apply fee

		}

		return false; // if we are not able to identify current model, then don't apply the fee
	},

	calculateLuxuryTax( vehiclePrice, saleClass, vehicleClass ) {
		let luxuryTax = 0;
		const carsCap = vmsData?.settings?.luxuryTaxCarsCap || 100000;
		const BoatsCap = vmsData?.settings?.luxuryTaxBoatsCap || 250000;

		// Guidelines as per AI-45443.
		const isNewSaleClass = ( 'new' === saleClass || 'neuf' === saleClass );
		const isCarEligible = carsCap < vehiclePrice && isNewSaleClass && ! [ 'boats', 'motorcycle' ].includes( vehicleClass );
		const isBoatEligible = BoatsCap < vehiclePrice && isNewSaleClass && 'boats' === vehicleClass;

		if ( isCarEligible || isBoatEligible ) {

			// Calculate on Total Pre-Tax Sales Price in excess of $100,000, i.e 20%.
			const onExcess = ( vehiclePrice - 100000 ) * .2;

			// Calculate on Total Pre-Tax Sales Price, i.e 10%.
			const onTotal = vehiclePrice * 0.1;
			luxuryTax = onTotal > onExcess ? onExcess : onTotal;
		}
		return luxuryTax;
	},
	applyLuxuryTax( inPaymentCalculator, vehicleData ) {
		let isLuxuryTaxEligible = false;
		const luxuryTaxEnabled = ( globalVars.federalLuxuryTaxToggle && globalVars.luxuryTaxOptimizely );
		if ( ! luxuryTaxEnabled ) {
			isLuxuryTaxEligible = false;
			return isLuxuryTaxEligible;
		}

		const isLocationActive = inPaymentCalculator ? globalVars.federalLuxuryTaxConfig.location.payment_calculator : globalVars.federalLuxuryTaxConfig.location.website_components;
		if ( luxuryTaxEnabled && isLocationActive ) {
			isLuxuryTaxEligible = true;

			// Check if vehicle is excluded for Luxury Tax.
			const exclutionListEnabled = globalVars.federalLuxuryTaxConfig.exclusion_list_toggle;
			if ( exclutionListEnabled ) {
				if ( globalVars.federalLuxuryTaxConfig.exclusion_list ) {
					const excludedModels = globalVars.federalLuxuryTaxConfig.exclusion_list.models;
					const excludedVehicles = globalVars.federalLuxuryTaxConfig.exclusion_list.vehicles;

					// Check excluded models.
					if ( excludedModels.includes( vehicleData.model ) ) {
						isLuxuryTaxEligible = false;
						return isLuxuryTaxEligible;
					}

					// Check excluded vehicles.
					const isVehicleExcluded = excludedVehicles.some( vehicle => {
						return Object.keys( vehicle ).some( model => model == vehicleData.model && vehicle[model] == vehicleData.year );
					});
					isLuxuryTaxEligible = ( isVehicleExcluded ) ? false : true;
					return isLuxuryTaxEligible;
				}
			}
			return isLuxuryTaxEligible;
		}
		return isLuxuryTaxEligible;
	},

	generateVdpLink( vehicle ) {
		if ( vehicle ) {
			let year = vehicle.year ? vehicle.year : '-';
			let make = vehicle.make ? vehicle.make.replace( /[/&#() ]/g, '-' ) : '-';
			let model = vehicle.model ? vehicle.model.replace( /[/&#() ]/g, '-' ) : '-';
			let city = vehicle.company_data.company_city ? vehicle.company_data.company_city.replace( /[/&#() ]/g, '-' ) : '-';
			let province = vehicle.company_data.company_province ? vehicle.company_data.company_province.replace( /[/&#() ]/g, '-' ) : '-';
			let vdpLink = `${globalVars.siteUrl}/vehicles/${year}/${make}/${model}/${city}/${province}/`.toLowerCase();
			vdpLink += `${vehicle.ad_id}/`;
			vdpLink += `?sale_class=${vehicle.sale_class.toLowerCase()}`;
			try {
				vdpLink = vdpLink.normalize( 'NFD' ).replace( /[\u0300-\u036f]/g, '' ); // Removing accents or special char for french, as per SEO Organic.
			} catch ( err ) {
				vdpLink = vdpLink.replace( /[\u0300-\u036f]/g, '' ); // Removing accents or special char for french, as per SEO Organic.
			}
			return vdpLink;
		}
	},

	vehiclePlaceholderImage( vehicle, fileExtension ) {
		let extension = fileExtension ? fileExtension : 'jpg';
		if ( vehicle && vehicle.is_npv ) {
			return `${globalVars.templateDirectory}/achilles/assets/images/srp-placeholder/${vehicle.vehicle_class_en.replace( / /g, '-' )}.${extension}`;
		} else {
			return `${globalVars.templateDirectory}/achilles/assets/images/srp-placeholder/PV.${extension}`;
		}
	},

	vehicleShowsFinance( vehicle, disableNew, disableUsed, newSetTerms, usedSetTerms, hideZeroFinance = null ) {
		if ( vmsData.settings.hideFinanceForOldModels ) {
			const currentYear = new Date().getFullYear();

			if ( 10 <= currentYear - vehicle?.year ) {
				return false;
			}
		}

		if ( vehicle && vehicle.finance && 4000 < vehicle.final_price ) {
			let programs = vehicle.finance;
			if ( 0 < programs.length ) {
				for ( let i = 0; i < programs.length; i++ ) {
					let financeCash = programs[i].finance_initial_price - programs[i].finance_final_price;
					if ( '0' === programs[i].finance_term && '0' === programs[i].finance_rate && 0 === financeCash ) {
						return false;
					}

					if ( 'undefined' === typeof programs[i].finance_term && 'undefined' === typeof programs[i].finance_rate && 0 === financeCash && hideZeroFinance ) {
						return false;
					}

					if ( utilities.isVehicleNew( vehicle ) && ! programs[i].hasOwnProperty( 'finance_term' ) && ! newSetTerms ) {
						return false;
					}

					if ( ! utilities.isVehicleNew( vehicle ) && ! programs[i].hasOwnProperty( 'finance_term' ) && ! usedSetTerms ) {
						return false;
					}
				}
			} else {
				if ( utilities.isVehicleNew( vehicle ) && ! newSetTerms ) {
					return false;
				}

				if ( ! utilities.isVehicleNew( vehicle ) && ! usedSetTerms ) {
					return false;
				}
			}

			if ( utilities.isVehicleNew( vehicle ) && ! disableNew ) {
				return true;
			}

			if ( ! utilities.isVehicleNew( vehicle ) && ! disableUsed ) {
				return true;
			}
		}
		return false;
	},

	initLocalStorage( storedDataName ) { //Makes sure of that the local storage is initialized as an array.
		let rawLocalData = localStorage.getItem( storedDataName );
		if ( 'undefined' != rawLocalData ) {
			let storedData = JSON.parse( rawLocalData );
			if ( null == storedData ) {
				localStorage.setItem( storedDataName, '[]' );
			}
		} else {
			localStorage.setItem( storedDataName, '[]' );
		}
	},

	setLocalStorage( dataName, dataToStore ) {
		if ( 'undefined' !== typeof ( Storage ) ) {
			localStorage.setItem( `${dataName}`, JSON.stringify( dataToStore ) );
		} else {
			console.warn( 'Sorry, your browser does not support Web Storage...' );
		}
	},

	getLocalStorage( storedData ) {
		let rawLocalData = localStorage.getItem( storedData );
		if ( null !== rawLocalData && 'undefined' != rawLocalData ) {
			let localData = JSON.parse( rawLocalData );
			return localData;
		} else {
			return rawLocalData = [];
		}
	},

	searchLocalStore( storedValue, storedArray, saved, others ) {
		if ( 'saved' === saved ) {
			for ( let i = 0; i < storedArray.length; i++ ) {
				if ( null !== storedArray[i]) {
					if ( storedValue === storedArray[i].vehicle && others === storedArray[i].othersCount ) {
						return true;
					}
				} else {
					continue;
				}
			}
		} else {
			for ( let i = 0; i < storedArray.length; i++ ) {
				if ( null !== storedArray[i]) {
					if ( storedValue === storedArray[i].vehicle ) {
						return true;
					}
				} else {
					continue;
				}
			}
		}
	},

	searchLocalStoreAndRemove( storedValue, storedArray ) {
		for ( let i = 0; i < storedArray.length; i++ ) {
			if ( null !== storedArray[i]) {
				if ( storedValue === storedArray[i].vehicle && undefined !== storedArray[i]) {
					storedArray.splice( i, 1 );
					return storedArray;
				}
			}
		}
		return storedArray;
	},

	debounce( callback, delay = 500 ) {
		let debounceTimer;
		return function() {
			const context = this;
			const args = arguments;
			clearTimeout( debounceTimer );
			debounceTimer = setTimeout( () => callback.apply( context, args ), delay );
		};
	},

	deepEqual( x, y ) {
		if ( x === y ) {
			return true;
		} else if ( ( 'object' == typeof x && null != x ) && ( 'object' == typeof y && null != y ) ) {
			if ( Object.keys( x ).length !== Object.keys( y ).length ) {
				return false;
			}

			for ( let prop in x ) {
				if ( y.hasOwnProperty( prop ) ) {
					if ( ! utilities.deepEqual( x[prop], y[prop]) ) {
						return false;
					}
				} else {
					return false;
				}
			}
			return true;
		}
		return false;
	},

	validateDomain( url ) {

		// This function will test if domain exist in url. If yes, pass true.
		if ( 0 <= url.indexOf( domainUrl )  ) {
			return true;
		}
		return false;
	},

	truncateString( string, totalCharacters ) {
		if ( totalCharacters < string.length ) {
			return string.slice( 0, totalCharacters ) + '...';
		} else {
			return string;
		}
	},

	incentiveDiff( initialPrice, finalPrice, breakdown ) {

		// If initial and final are the same return -1 to invalidate
		if ( initialPrice === finalPrice ) {
			return -1;
		}

		let difference = initialPrice - finalPrice;
		let discount = 0;

		// Check the breakdown is defined.
		if ( ! breakdown || 0 === breakdown.length ) {
			return difference;
		}

		// Loop through to add the discount only if they are not adjustments
		for ( let i = 0; i < breakdown.length; i++ ) {

			// Adjustment is not a discount so skip it.
			if ( 'adjustment' === breakdown[i].type  ) {
				continue;
			}

			if ( 0 === breakdown[i].amount ) {
				discount += Math.round( initialPrice * breakdown[i].amount_percent / 100 );
			} else {
				discount += Math.round( breakdown[i].amount );
			}
		}
		const final = difference - discount;
		if ( -5 < final && 5 > final ) { // small buffer for little calculation issues
			return 0;
		} else {
			return final;
		}
	},

	vehicleDataLayer( vehicle ) {
		return {
			'make': vehicle.make,
			'model': vehicle.model,
			'year': vehicle.year,
			'condition': 1 === vehicle.certified ? 'CPO' : utilities.translatetoEnglishForShiftDigital( vehicle.sale_class, 'vehicleStatus' ),
			'id': vehicle.ad_id,
			'bodyStyle': vehicle.body_style,
			'odometer': vehicle.odometer,
			'stockNumber': vehicle.stock_number,
			'vin': vehicle.vin,
			'colour': vehicle.exterior_color,
			'msrp': vehicle.initial_price,
			'internetPrice': vehicle.final_price,
			'trim': vehicle.trim,
			'status': '',
			'transmission': vehicle.transmission,
			'cpo': vehicle.certified,
			'kilometers': vehicle.fuel_economy_city_km,
			'engine': vehicle.engine,
			'driveTrain': vehicle.drive_train,
			'doors': vehicle.doors,
			'fuelType': utilities.translatetoEnglishForShiftDigital( vehicle.fuel_type, 'vehicleFuelType' ),
			'vehicleClass': vehicle.vehicle_class,
			'dateOnLot': vehicle.date_on_lot,
			'showroom': 'false',
			'childStoreId': vehicle.trader_company_id,
			'childStoreName': vehicle.company_data.company_name
		};
	},

	setSimpleFields( vehicle, currentVdpUrl ) {
		jQuery( '.vdp_vehicle, [name=vdp-vehicle]' ).val( vehicle.year + ' ' + vehicle.make + ' ' + vehicle.model + ( vehicle.trim ? ' ' + vehicle.trim  : '' ) );
		jQuery( '.vdp_year, [name=vdp-year]' ).val( vehicle.year );
		jQuery( '.vdp_make, [name=vdp-make]' ).val( vehicle.make );
		jQuery( '.vdp_model, [name=vdp-model]' ).val( vehicle.model );
		jQuery( '.vdp_trim, [name=vdp-trim]' ).val( vehicle.trim );
		jQuery( '.vdp_bodystyle, [name=vdp-bodystyle]' ).val( vehicle.body_style );
		jQuery( '.vdp_price, [name=vdp-price]' ).val( vehicle.final_price && 0 < vehicle.final_price ? utilities.formatCommas( vehicle.final_price, true, true ) : translatedStrings.vehicleCard.contactUs );
		jQuery( '.vdp_doors, [name=vdp-doors]' ).val( vehicle.doors );
		jQuery( '.vdp_exteriorcolor, [name=vdp-exteriorcolor]' ).val( vehicle.exterior_color );
		jQuery( '.vdp_interiorcolor, [name=vdp-interiorcolor]' ).val( vehicle.interior_color );
		jQuery( '.vdp_transmission, [name=vdp-transmission]' ).val( vehicle.transmission );
		jQuery( '.vdp_odometer, [name=vdp-odometer]' ).val( vehicle.odometer );
		jQuery( '.vdp_vin, [name=vdp-vin]' ).val( vehicle.vin );
		jQuery( '.vdp_stock, [name=vdp-stock]' ).val( vehicle.stock_number );
		jQuery( '.vdp_saleclass, [name=vdp-saleclass]' ).val( 1 === vehicle.certified ? 'CPO' : utilities.translatetoEnglishForShiftDigital( vehicle.sale_class, 'vehicleStatus' ) );
		jQuery( '.vdp_inventory_url, [name=vdp-inventory-url]' ).val( currentVdpUrl );
		jQuery( '.vdp_adId, [name=vdp-adid]' ).val( vehicle.ad_id );
		jQuery( '.vdp_drivetrain, [name=vdp-drivetrain]' ).val( vehicle.drive_train );
		jQuery( '.vdp_engine, [name=vdp-engine]' ).val( vehicle.engine );
		jQuery( '.vdp_fuelType, [name=vdp-fuelType]' ).val( utilities.translatetoEnglishForShiftDigital( vehicle.fuel_type, 'vehicleFuelType' ) );
		jQuery( '.vdp_msrp, [name=vdp-msrp]' ).val( vehicle.msrp );

		if ( vehicle.image ) {
			let image = '';
			if ( vehicle.image.image_md ) {
				image = utilities.updateMediaCDN( vehicle.image.image_md );
			} else if ( vehicle.image[0].image_md ) {
				image = utilities.updateMediaCDN( vehicle.image[0].image_md );
			}

			jQuery( '.vdp_img_url, [name=vdp-img-url]' ).val( image );
		} else {
			jQuery( '.vdp_img_url, [name=vdp-img-url]' ).val( globalVars.templateDirectory + '/achilles/assets/images/srp-placeholder/PV.jpg' );
		}

		if ( 'company_data' in vehicle ) {
			jQuery( '[name=vdp-company-name]' ).val( vehicle.company_data.company_name );
		}

		if ( 'trader_company_id' in vehicle ) {
			jQuery( '[name=vdp-trader-company-id]' ).val( vehicle.trader_company_id );
		}

		// Hide initial price if same as final
		if ( vehicle.initial_price && vehicle.final_price < vehicle.initial_price ) {
			jQuery( '.vdp_initialPrice, [name=vdp-initialPrice]' ).val( utilities.formatCommas( vehicle.initial_price, true, true ) );
			jQuery( '.vdp_initialPriceLabel, [name=vdp-initialPrice-label]' ).val( translatedStrings.emailBody.initialPrice );
		}

	},

	getSessionId( key ) {
		let id = null;
		if ([ 'gaClientId', 'udSessionId' ].includes( key ) ) {
			dataLayer.forEach( data => {
				if ( data[key]) {
					id = data[key];
				}
			});
		}

		return id;
	},

	rgbToHex( rgbStr ) {
		if ( ! rgbStr.startsWith( 'rgb' ) ) {
			return rgbStr;
		}

		let colors = rgbStr.match( /\d+/g );
		if ( 3 > colors.length ) {
			return rgbStr;
		}

		const rgb = ( colors[0] << 16 ) | ( colors[1] << 8 ) | ( colors[2] << 0 );
		return '#' + ( 0x1000000 + rgb ).toString( 16 ).slice( 1 );
	},

	getElementPosition: ( element ) => {
		const { left, top, width } = element.getBoundingClientRect();
		const scrollBarWidth = window.innerWidth - document.documentElement.clientWidth;
		const halfWindowWidth = Math.round( ( window.outerWidth - scrollBarWidth ) / 2 );
		const centerElementX = Math.round( left + ( width / 2 ) );
		const yPosition = window.innerHeight / 2 < top ? 'bottom' : 'top';
		let xPosition;

		if ( halfWindowWidth === centerElementX ) {
			xPosition = 'center';
		} else if ( halfWindowWidth < centerElementX ) {
			xPosition = 'right';
		} else {
			xPosition = 'left';
		}

		return `${yPosition}_${xPosition}`;
	},

	decodeSingleQuotes( string ) {
		if ( 'string' === typeof string ) {
			return string.replace( /&#039;/g, '\'' );
		}

		return '';
	},

	frModelName( modelName ) {
		if ( 'fr' == globalVars.language ) {
			switch ( modelName ) {
			case 'Civic Hatchback':
			case 'Civic Hayon':
				return 'Civic À Hayon';
			case 'MX-30 électrique':
				return 'MX-30';
			case 'CX-90 hybride léger':
			case 'CX-90 hybride rechargeable':
				return 'CX-90';
			default:
				return modelName;
			}
		}
		return modelName;
	},

	// Adds mandatory consent checkbox message before submitting the form
	addConsentCheckbox( _form ) {
		let activeLang = document.documentElement.lang.replace( /-/g, '_' ); // Active language of a site.

		let langExtension = globalVars.siteUrl;
		langExtension = langExtension?.split( '/' )[langExtension?.split( '/' )?.length - 1];

		if ( ! [ 'en', 'fr' ].includes( langExtension ) ) {
			langExtension = '';
		} else {
			langExtension = `${langExtension}/`;
		}

		let consentMsgEng = `By submitting this information, you are accepting that it may be collected, used and disclosed as described in our <a href="/${langExtension}privacy-policy">privacy policy.</a>`;
		let consentMsgFr = `En nous soumettant ces renseignements, vous consentez à ce qu’ils soient recueillis, utilisés et communiqués conformément à notre <a href="/${langExtension}privacy-policy">politique sur la protection des renseignements personnels</a>`;

		let consentMsg = 'fr_CA' === activeLang ? consentMsgFr : consentMsgEng;
		let requiredMsg = 'fr_CA' === activeLang ? 'Ce champ est obligatoire' : 'This field is required';


		let form = jQuery( _form );
		let submitButton = form.find( '.wpcf7-submit' );

		// Check if the consentCheckbox already exists in the form
		let existingConsentCheckbox = form.find( '.wpcf7-form-control-wrap.consent_checkbox' );
		if ( 0 === existingConsentCheckbox.length ) {
			let consentCheckbox = $( `
										<span class="wpcf7-form-control-wrap consent_checkbox" style="width: 100%;">
											<span class="wpcf7-form-control">
												<div class="wpcf7-list-item">
													<label>
														<input type="checkbox" name="consent_checkbox" value="on">
														<span class="wpcf7-list-item-label">${consentMsg}</span>
													</label>
												</div>
											</span>
										</span>
									` );
			consentCheckbox.insertBefore( submitButton );

			// Prevent form submission if the consent checkbox is not checked.
			let consentCheckboxValidation = false;
			submitButton[0].addEventListener( 'click', function( e ) {
				if ( ! consentCheckbox.find( 'input' ).prop( 'checked' ) ) {
					e.preventDefault();
					if ( ! consentCheckboxValidation ) {
						consentCheckbox.append( `
													<div class="validated"></div>
													<div class="validated__label">${requiredMsg}</div>
												` );
						consentCheckboxValidation = true;
					}
				}
			});
		}
	},

	shouldShowStockCount( stockCount, make ) {
		if ( ! Array.isArray( vmsData.settings.defaultVehicleMake ) && stockCount ) {
			return true;
		}

		return 0 < stockCount && vmsData.settings.defaultVehicleMake.includes( make );
	},

	extractPrimaryDomain( hostname ) {
		const parts = hostname.split( '.' ).filter( part => 'www' !== part );

		if ( 0 < parts.length ) {
			return parts[0];
		}

		return null;
	},

	swapPrimaryDomain( url1, url2 ) {
		const getHostnameParts = ( url ) => {
			const urlObj = new URL( url );
			return urlObj.hostname.split( '.' );
		};
		const hostnameParts1 = getHostnameParts( url1 );
		const hostnameParts2 = getHostnameParts( url2 );
		hostnameParts2[0] = hostnameParts1[1];
		const primaryDomain2 = hostnameParts2.slice( -3 ).join( '.' );
		const newHostname = primaryDomain2;
		const urlObj1 = new URL( url1 );
		urlObj1.hostname = newHostname;
		return urlObj1.toString();
	},

	getOEMId( vehicleSiteOrigin, parentVMSId ) {

		//Correct URL for local development purposes if we are on local, throwaway once endpoint exists in PROD ASPD-11180
		if ( 'dev' === globalVars.serverEnvironment ) {
			vehicleSiteOrigin = vehicleSiteOrigin.replace( 'https', 'http' );
			vehicleSiteOrigin = vehicleSiteOrigin.replace(
				/\.\w+($|\/)/,
				'.wordpress.localhost$1'
			);
		}

		if ( 'staging' === globalVars.serverEnvironment ) {
			vehicleSiteOrigin = utilities.swapPrimaryDomain( vehicleSiteOrigin, `${window.location.origin}/` );
		}

		if ( '/' !== vehicleSiteOrigin.slice( -1 ) ) {
			vehicleSiteOrigin = `${vehicleSiteOrigin}/`;
		}

		return new Promise( ( resolve, reject ) => {
			fetch( `${vehicleSiteOrigin}wp-json/achilles/v1/get_oem_id?vmsId=${parentVMSId}` )
				.then( res => {
					if ( ! res.ok ) {
						throw new Error( 'Network response was not ok' );
					}
					return res.json();
				})
				.then( oemID => {
					if ( 404 === oemID?.data?.status ) {
						reject( new Error( 'OEM ID not found, status 404' ) );
					} else {
						resolve( oemID );
					}
				})
				.catch( error => {
					reject( error );
				});
		});
	},

	getCurrentAndNextYears() {
		const currentYear = new Date().getFullYear().toString();

		// Next year is considered until the end of January
		const currentDate = new Date();
		const endOfNextJanuary = new Date( currentDate.getFullYear() + 1, 0, 31 ); // January 31 of next year

		// Check if current date is before or on January 31st of the next year
		const nextYear = ( currentDate <= endOfNextJanuary ) ? ( currentDate.getFullYear() + 1 ).toString() : currentYear;
		return [ nextYear, currentYear ];
	},

	updateMediaCDN( media ) {
		if ( ! globalVars.updateVehicleMediaCDN ) {
			return media;
		}

		// Replace old prod CDN with updated CDN.
		let newMedia = media.replace( 'tdrvehicles.azureedge.net', '1s-photomanager-prd.autotradercdn.ca' );
		newMedia = newMedia.replace( 'tdrvehicles2.azureedge.net', '1s-photomanager-prd.autotradercdn.ca' );
		newMedia = newMedia.replace( 'tdrvids.azureedge.net', 'mkt-vehiclevideos-prd.autotradercdn.ca' );

		// Replace old staging CDN with updated CDN.
		newMedia = newMedia.replace( 'tdrvehiclestest.azureedge.net', 'tdrvehiclestest.autotradercdn.ca' );
		newMedia = newMedia.replace( 'tdrpmimagestest.azureedge.net', '1s-photomanager-qa.autotradercdn.ca' );
		newMedia = newMedia.replace( 'tdrphotoqa.azureedge.net', 'mkt-vehicleimages-qa.autotradercdn.ca' );

		return newMedia;
	},

	processTemplate( templateString, globalThis ) {
		const templateRegex = /\$\{([^}]+)\}/g;

		return templateString?.replace( templateRegex, ( _, expr ) => {
			try {
				return expr.split( '.' ).reduce( ( acc, part ) => acc?.[part], globalThis ) ?? '';
			} catch {
				return '';
			}
		});
	},

	updateDataLayerWithOptimizelyContext( optimizelyKey, dataLayerObj ) {
		const isEnabled = globalVars[optimizelyKey]?.enabled;

		if ( ! isEnabled || dataLayerObj.hasOwnProperty( 'optimizelyAbTest' ) ) {
			return dataLayerObj;
		}

		if ( isEnabled ) {
			const modifiedoptimizelyABTestObj = {
				...globalVars[optimizelyKey]
			};

			delete modifiedoptimizelyABTestObj.variables;

			const modifiedDataLayer = {
				...dataLayerObj,
				optimizelyAbTest: modifiedoptimizelyABTestObj
			};

			return modifiedDataLayer;
		}
	},

	getABTestContactUsValue( eventData ) {
		const contactUsABTestingValue = globalVars?.optimizelyABTestContactUs;

		if ( 'undefined' !== typeof contactUsABTestingValue && null !== contactUsABTestingValue ) {
			const { variation, enabled, feature, user } = contactUsABTestingValue;
			eventData.optimizelyAbTest = { variation, enabled, feature, user };
		}
		return eventData;
	},

	getABTestingContactFormCloseValue() {
		const closeButtonABTestValue = globalVars?.optimizelyABTestCloseButton;
		if ( 'undefined' !== typeof closeButtonABTestValue && null !== closeButtonABTestValue ) {
			const { variables, enabled } = closeButtonABTestValue;
			if ( enabled ) {
				return variables.active;
			}
			return false;
		}
		return false;
	},

	modifyDataLayerForAbTest( dataLayerObj, inputs ) {
		const abTestInput = inputs.find( input => 'a-b-test-contact-form' === input.name );
		const isAbTestForm = !! abTestInput;
		const modifiedDataLayerObj = isAbTestForm ?
			utilities.updateDataLayerWithOptimizelyContext( abTestInput?.value, dataLayerObj ) :
			dataLayerObj;

		return modifiedDataLayerObj;
	}
};

module.exports = utilities;

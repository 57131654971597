import { trackDealershipSettings } from 'AchillesJs/client-partials/datalayer-manager';

export default function() {
	const networkUrl = dealershipData.network_url;
	const userRole = dealershipData.current_user_role[0];
	const isSuperAdmin = ( '1' === dealershipData.is_super_admin[0] || 'true' === dealershipData.is_super_admin[0]) ? true : false;
	const adminRole = ( userRole && -1 < userRole.search( 'administrator' ) ) ? true : false;
	const isFCA = ( networkUrl && -1 < networkUrl.search( 'fcatadvantage' ) ) ? true : false;
	const datalayerUser = dataLayer.filter( obj => {
		return obj.user;
	});

	let fcaUserDomain = false;
	let convertusTraderUserDomain = false;
	let allowUser = true; // lets default to current (no restriction)

	if ( datalayerUser.length ) {
		const userEmailDomain = datalayerUser[0].user.emailDomain;

		fcaUserDomain = ( -1 < userEmailDomain.search( 'fcatadvantage' ) ) ? true : false; //FCA user
		convertusTraderUserDomain = ( -1 < userEmailDomain.search( /convertus|trader/ ) ? true : false ); //convertus or trader user

		addDataLayerVariable( userRole, networkUrl );
	}

	/**
	 * DISABLE Email Leads Section on FCA Network when
	 * user domain is FCA or non-Convertus/Trader and does not have admin rights.
	 *
	 * Super Admin Users - not blocked.
	 * Convertus/Trader emails - not blocked.
	 * fcatadvantage User Domain - blocked (if not admin).
	 * other User Domain - blocked (if not admin).
	 * */
	if ( isFCA ) {
		if ( fcaUserDomain || ! convertusTraderUserDomain ) { //non convertus/trader domain or fca domain gets denied access if they dont have admin access.
			allowUser = adminRole;
		}

		// This overrides anything since we want Super Admin to have access to everything
		if ( isSuperAdmin ) {
			allowUser = true;
		}

		if ( ! allowUser ) {
			disableEmailLeadsSection();
			disableADFLeadsSection();
		}
	}

	/**
	 * METHODS
	 */
	function disableEmailLeadsSection() {
		const emailLeadsSection = document.getElementsByClassName( 'section-block email' );
		if ( emailLeadsSection.length ) {
			let emailInputs = jQuery( '#email :input' );
			let leadsEmailTags = jQuery( '#email span.email-tag' );
			blockLeadsInputFields( emailInputs );
			blockEmailTags( leadsEmailTags );
		}
	}

	function disableADFLeadsSection() {
		const adfLeadsSection = document.getElementsByClassName( 'section-block adf' );
		if ( adfLeadsSection.length ) {
			let adfInputs = jQuery( '#adf :input' );
			let adfEmailTags = jQuery( '#adf span.email-tag' );
			blockLeadsInputFields( adfInputs );
			blockEmailTags( adfEmailTags );
		}
	}

	function blockLeadsInputFields( inputs ) {
		if ( inputs.length ) {
			Object.entries( inputs ).forEach( ( input ) => {
				let placeholderText = input[1].placeholder;
				if ( placeholderText !== undefined ) {
					placeholderText = 'locked';
					input[1].placeholder = placeholderText;
					input[1].style.cursor = 'no-drop';
				}
			});
			inputs.attr( 'disabled', true );
		}
	}

	function blockEmailTags( emailTags ) {
		if ( emailTags.length ) {
			[ ...emailTags ].forEach( ( tag ) => {
				jQuery( '.dashicons' ).remove();
				tag.setAttribute( 'style', 'cursor: default' );
				tag.setAttribute( 'style', 'pointer-events: none' );
			});
		}
	}

	function addDataLayerVariable( role, url ) {
		datalayerUser[0].user.userRole = role;
		trackDealershipSettings( url );
	}
}

export default function() {

	const awardYearEnabled = document.querySelector( 'input[name="dealership_settings_general_awards_toggle"]' );
	if ( awardYearEnabled ) {
		const years = [ 2020, 2021, 2022, 2023, 2024 ];
		const selectors = [];

		years.forEach( year => {
			selectors.push(  document.querySelector( `.award_year_${year}` ) );
		});

		toggleAwardYears();

		// Add event listener for checkbox click.
		awardYearEnabled.addEventListener( 'click', function() {
			toggleAwardYears();
		});

		function toggleAwardYears() {
			if ( true === awardYearEnabled.checked ) {
				selectors.forEach( selector => {
					selector.style.visibility = 'visible';
				});
			} else {
				selectors.forEach( selector => {
					selector.style.visibility = 'collapse';
				});
			}
		}
	}
}

import VMasker from 'vanilla-masker';
import { addListener } from 'AchillesJs/client-partials/utilities';
import Phones from './phone-numbers';
import AwardYears from './award_years';
import LeadsRestriction from './leads-restriction';

// Formats string to appear as phone number
function phoneFormat( input ) {
	input = input.replace( /\D/g, '' );
	input = input.substring( 0, 10 );

	let size = input.length;
	if ( 0 == size ) {
		input = input;
	} else if ( 4 > size ) {
		input = '(' + input;
	} else if ( 7 > size ) {
		input = '(' + input.substring( 0, 3 ) + ') ' + input.substring( 3, 6 );
	} else {
		input = '(' + input.substring( 0, 3 ) + ') ' + input.substring( 3, 6 ) + '-' + input.substring( 6, 10 );
	}
	return input;
}

jQuery( document ).ready( function( $ ) {
	LeadsRestriction();
	Phones();
	AwardYears();
	new Vue({
		el: '#department-settings-wrap',
		data: {
			loaded: false,
			hours: [],
			emptyDepartment: {
				mon_open: '',
				mon_close: '',
				tue_open: '',
				tue_close: '',
				wed_open: '',
				wed_close: '',
				thu_open: '',
				thu_close: '',
				fri_open: '',
				fri_close: '',
				sat_open: '',
				sat_close: '',
				sun_open: '',
				sun_close: '',
				'department-hours-title': ''
			}
		},
		watch: {
			formattedHours( val ) {
				document.getElementById( HoursId ).value = val;
				if ( this.loaded && '' !== val ) {
					jQuery( '#' + HoursId ).trigger( 'change' ); // Set content in translation hidden field.
				}
			}
		},
		created() {
			let hours = JSON.parse( DBhours );
			for ( let department in hours ) {
				if ( hours.hasOwnProperty( department ) ) {
					this.hours.push( hours[department]);
				}
			}
			this.$nextTick( () => {
				this.loaded = true;
			});
		},
		mounted() {
			let _this = this;
			jQuery( '.cs-lang-switch' ).on( 'click', function() {

				if ( '' !== jQuery( '#' + HoursId ).val() ) {
					_this.$nextTick( () => {
						_this.hours = []; // Remove previous content.
						let hours =  JSON.parse( jQuery( '#' + HoursId ).val() );

						for ( let department in hours ) {
							if ( hours.hasOwnProperty( department ) ) {
								_this.hours.push( hours[department]);
							}
						}
					});
				}
			});

			this.maskPhone();
		},
		computed: {
			formattedHours() {
				let hours = {};
				for ( let i = 0; i < this.hours.length; i++ ) {
					hours[this.hours[i]['department-hours-title']] = this.hours[i];
				}
				return JSON.stringify( hours );
			}
		},
		methods: {
			maskPhone() {
				addListener( document.querySelectorAll( '#phone input' ), 'input', e => {
					VMasker( e.target ).unMask();
					VMasker( e.target ).maskPattern( 10 < e.target.value.length ? '9 (999) 999-9999' : '(999) 999-9999' );
				});
			},
			addDepartment() {
				this.hours.push({ ...this.emptyDepartment });
				this.loaded = true;
			},
			removeDepartment( index ) {
				console.log( index );
				if ( confirm( 'Are you sure you want to delete?' ) ) {
					this.hours.splice( index, 1 );
				}
			},
			formatContent( event ) {
				const parsedValue =  event.target.value.replace( /[^\w\s\:\.\-\+\=\_zàâçéèêëîïôûùüÿñæœ]/gi, '' );
				event.target.value = parsedValue;
				return parsedValue;
			}
		}
	});
});

const { translatetoEnglishForShiftDigital } = require( '../client-partials/utilities' );
let shiftDigital = {
	loadShiftDigitalPageView() {
		if ( 'undefined' !== typeof shiftDigitalData ) {
			sd( 'create', shiftDigitalData.clientId, shiftDigitalData.retailerId, shiftDigitalData.providerId );
			sd( 'dataLayer', shiftDigitalData.pageLoad );
			sd( 'send', 'pageview' );
		}
	},
	trackQuickViewOpen( vehicle ) {
		const vehicleDetails = stringifyVals({
			status: 1 === vehicle.certified ? 'CPO' : translatetoEnglishForShiftDigital( vehicle.sale_class, 'vehicleStatus' ),
			year: vehicle.year,
			make: vehicle.make,
			model: vehicle.model,
			trim: vehicle.trim,
			engine: vehicle.engine,
			transmission: vehicle.transmission,
			drivetrain: vehicle.drive_train,
			interiorColor: vehicle.interior_color,
			exteriorColor: vehicle.exterior_color,
			vin: vehicle.vin,
			msrp: vehicle.msrp,
			displayedPrice: vehicle.final_price,
			fuelType: translatetoEnglishForShiftDigital( vehicle.fuel_type, 'vehicleFuelType' )
		});

		sd( 'dataLayer', {
			vehicleDetails,
			sessionId: shiftDigitalData.pageLoad.sessionId,
			events: 'vehicleQuickView'
		});
		sd( 'send' );
	},

	trackFormShown( obj ) {
		let formType = formTypeMap( obj.formType );
		const data = stringifyVals({
			formType: formType,
			displayType: ( obj.display ) ? obj.display : 'in-page',
			sessionId: shiftDigitalData.pageLoad?.sessionId ? shiftDigitalData.pageLoad?.sessionId : '',
			events: 'formShown'
		});

		if ( 'vehicle' in obj ) {
			data.formVehicle = stringifyVals( formVehicle( obj.vehicle ) );
		} else if ( false === 'vehicle' in obj && 'undefined' !== typeof vmsData && vmsData.vehicle ) { // vdp in-page forms
			data.formVehicle = stringifyVals({
				status: 1 === vmsData.vehicle.certified ? 'CPO' : translatetoEnglishForShiftDigital( vmsData.vehicle.sale_class, 'vehicleStatus' ),
				year: vmsData.vehicle.year,
				make: vmsData.vehicle.make,
				model: vmsData.vehicle.model,
				trim: vmsData.vehicle.trim,
				engine: vmsData.vehicle.engine,
				transmission: vmsData.vehicle.transmission,
				drivetrain: vmsData.vehicle.drive_train,
				interiorColor: vmsData.vehicle.interior_color,
				exteriorColor: vmsData.vehicle.exterior_color,
				vin: vmsData.vehicle.vin,
				msrp: vmsData.vehicle.msrp,
				displayedPrice: vmsData.vehicle.final_price,
				fuelType: translatetoEnglishForShiftDigital( vmsData.vehicle.fuel_type, 'vehicleFuelType' )
			});
		}
		sd( 'dataLayer', data );
		sd( 'send' );
	},

	trackFormSent( obj ) {
		let formType = formTypeMap( obj.contactForm.type );
		let leadType = getLeadType( obj.contactForm.type );
		let formOptIn;
		if  ( 'yes' === obj.contactForm.optIn ) {
			formOptIn = 'in-explicit';
		} else if ( 'no' === obj.contactForm.optIn ) {
			formOptIn = 'out';
		} else {
			formOptIn = 'in-implicit';
		}

		const data = stringifyVals({
			leadId: obj.contactForm.submissionId,
			formType: formType,
			leadType: leadType,
			formOptIn: formOptIn,
			prefContact: translatetoEnglishForShiftDigital( obj.contactForm.preferredContact, 'prefContact' ),
			displayType: ( obj.contactForm.display ) ? obj.contactForm.display : 'in-page',
			sessionId: shiftDigitalData.pageLoad.sessionId,
			events: 'formSubmission'
		});

		if ( 'vehicle' in obj ) {
			data.formVehicle = stringifyVals( formVehicle( obj.vehicle ) );
		}

		if ( 'book service form' === obj.contactForm.type ) {
			sd( 'dataLayer', {
				sessionId: shiftDigitalData.pageLoad.sessionId,
				events: 'scheduleServiceClick'
			});
			sd( 'send' );
			sd( 'dataLayer', data );
			sd( 'send' );
		} else {
			sd( 'dataLayer', data );
			sd( 'send' );
		}
	},

	trackFormStart( obj ) {
		let formType = formTypeMap( obj.formType );
		const data = {
			formType: formType,
			displayType: ( obj.display ) ? obj.display : 'in-page',
			sessionId: shiftDigitalData.pageLoad.sessionId,
			events: 'formInitiation'
		};

		if ( 'vehicle' in obj ) {
			data.formVehicle = stringifyVals( formVehicle( obj.vehicle ) );
		}

		sd( 'dataLayer', data );
		sd( 'send' );
	},

	trackFormFieldInteraction( obj ) {
		let formType = formTypeMap( obj.formType );
		const data = stringifyVals({
			formType: formType,
			displayType: ( obj.display ) ? obj.display : 'in-page',
			formFieldName: obj.fieldName,
			sessionId: shiftDigitalData.pageLoad.sessionId,
			events: 'formFieldInteraction'
		});

		if ( 'vehicle' in obj ) {
			data.formVehicle = stringifyVals( formVehicle( obj.vehicle ) );
		}

		sd( 'dataLayer', data );
		sd( 'send' );
	},

	trackFormError( obj ) {
		let formType = obj.formType ? formTypeMap( obj.formType ) : '';
		const data = stringifyVals({
			formType: formType,
			displayType: ( obj.display ) ? obj.display : 'in-page',
			formError: obj.errorMessage ? obj.errorMessage : '',
			sessionId: shiftDigitalData.pageLoad.sessionId,
			events: 'formSubmissionError'
		});

		if ( 'vehicle' in obj ) {
			data.formVehicle = stringifyVals( formVehicle( obj.vehicle ) );
		}

		sd( 'dataLayer', data );
		sd( 'send' );
	},

	trackSliderClick( slide ) {
		sd( 'dataLayer', {
			carouselClick: {
				assetPosition: slide.i,
				assetName: slide.image
			},
			sessionId: shiftDigitalData.pageLoad.sessionId,
			events: 'carouselClick'
		});
		sd( 'send' );
	},

	trackTypedSearchEvent( keyword ) {
		sd( 'dataLayer', {
			typedSearchContent: keyword,
			sessionId: shiftDigitalData.pageLoad.sessionId,
			events: 'typedSearch'
		});
		sd( 'send' );
	},

	trackOffer( offerInfo ) {
		sd( 'dataLayer', {
			offerClick: offerInfo,
			sessionId: shiftDigitalData.pageLoad.sessionId,
			events: 'offerClick'
		});
		sd( 'send' );
	},

	trackClickToCallDepartment( department ) {
		sd( 'dataLayer', {
			clickToCallDepartment: department,
			sessionId: shiftDigitalData.pageLoad.sessionId,
			events: 'clickToCall'
		});
		sd( 'send' );
	},

	trackGetDirections() {
		sd( 'dataLayer', {
			sessionId: shiftDigitalData.pageLoad.sessionId,
			events: 'getDirections'
		});
		sd( 'send' );
	},

	trackServiceOffer( special, category ) {
		if ( 'undefined' !== typeof category && ( 'service' === category.toLowerCase() || 'services' === category.toLowerCase() ) ) {

			sd( 'dataLayer', {
				serviceOfferClick: {
					offerCategory: 'Service',
					offerType: 'Specials',
					offerName: special.name,
					offerExpire: special.expiryDate,
					offerStatus: 'active'
				},
				sessionId: shiftDigitalData.pageLoad.sessionId,
				events: 'serviceOfferClick'
			});
			sd( 'send' );
		}
	},

	trackSearchResults( filters ) {
		const filterSearch = {};

		// Used in our implementation, should be color for Shift Digital.
		if ( 'extColour' in filters ) {
			filters.color = filters.extColour;
			delete filters.extColour;
		}

		// All years in range should be included.
		if ( '' !== filters.search.minYear ) {
			const years = [];
			let year = filters.search.minYear;
			while ( year <= filters.search.maxYear ) {
				years.push( year );
				year += 1;
			}
			filterSearch.year = pipeDelimit( years );
		}

		[ 'status', 'make', 'model', 'trim', 'color', 'driveTrain', 'minPrice', 'maxPrice', 'bodyStyle', 'features' ].forEach( key => {
			if ( key in filters && ! empty( filters[key]) ) {
				filterSearch[key] = pipeDelimit( filters[key]);
			} else if ( key in filters.search && ! empty( filters.search[key]) ) {
				filterSearch[key] = pipeDelimit( filters.search[key]);
			}
		});

		// Change 'Certified' status to 'CPO'
		if ( 'undefined' !== typeof filterSearch.status && 'Certified' === filterSearch.status ) {
			filterSearch.status = 'CPO';
		}

		if ( ! filters.hasOwnProperty( 'firstLoad' ) || null === filters.firstLoad ) {
			this.loadShiftDigitalPageView();
		}

		// Only fire if filterSearch is not empty
		if ( 0 < Object.keys( filterSearch ).length ) {
			let filterSearchObj = {
				countSearchResults: filters.search.numberResults,
				filterSearch,
				sessionId: shiftDigitalData.pageLoad.sessionId,
				events: 'filterSearch'
			};
			this.loadSearchFilter( filterSearchObj );
		}

	},

	trackScheduleService() {
		sd( 'dataLayer', {
			sessionId: shiftDigitalData.pageLoad.sessionId,
			events: 'scheduleServiceClick'
		});
		sd( 'send' );
	},

	loadSearchFilter( obj ) {
		sd( 'dataLayer', obj );
		sd( 'send' );
	},

	trackLinkClick() {
		sd( 'dataLayer', {
			sessionId: shiftDigitalData.pageLoad.sessionId,
			linkType: 'Monroney Label',
			events: 'linkClick'
		});
		sd( 'send' );
	}

};

// Stringify all values in object as per Shift Digital requirement.
function stringifyVals( obj ) {
	Object.entries( obj ).forEach( entry => {
		const key = entry[0];
		const val = entry[1];

		if ( null !== val && val.constructor !== String ) {
			obj[key] = JSON.stringify( val );
		}
	});

	return obj;
}

function formVehicle( data ) {
	return {
		status: vmsData && vmsData.vehicle &&  1 === vmsData.vehicle.certified ? 'CPO' : translatetoEnglishForShiftDigital( data.condition, 'vehicleStatus' ),
		year: data.year,
		make: data.make,
		model: data.model,
		trim: data.trim,
		engine: data.engine,
		transmission: data.transmission,
		drivetrain: data.drivetrain,
		interiorColor: data.interiorColour,
		exteriorColor: data.colour,
		vin: data.vin,
		msrp: data.msrp,
		displayedPrice: data.internetPrice.replace( '$', '' ).replace( ',', '' ),
		fuelType: translatetoEnglishForShiftDigital( data.fuelType, 'vehicleFuelType' )
	};
}

/**
 * Transfoms arrays and comma-separated strings into pipe-separated strings.
 *
 * @param {*} val String or array to transform.
 */
function pipeDelimit( val ) {
	let newVal = val;

	if ( val.constructor === Array ) {
		newVal = val.join( '|' );
	} else if ( val.constructor === String ) {
		newVal = val.replace( /,/g, '|' );
	}

	if ( newVal.constructor === String ) {
		newVal = newVal.replace( /\%20/g, ' ' );
	}

	return newVal;
}

/**
 * Checks if val is an empty string or array.
 *
 * @param {*} val value to check.
 */
function empty( val ) {
	if ( '' === val ) {
		return true;
	}

	if ( val.constructor === Array && 0 === val.length ) {
		return true;
	}

	return false;
}

/**
 * Map type to restricted values based on form title.
 * Any new platform forms will need to be added into this map object. Remove slashes or dashes.
 *
 * @param string currentForm form title.
 */
function formTypeMap( currentForm ) {
	let newType = 'Dealer Custom';
	const typeMap = [
		{
			mappedType: 'Email a Friend',
			forms: [ 'email a friend form', 'special email a friend', 'vdp email a friend form' ]
		},
		{
			mappedType: 'Employment',
			forms: [ 'job application' ]
		},
		{
			mappedType: 'Finance',
			forms: [ 'apply for credit', 'apply for credit for page', 'finance application', 'prequalify for credit', 'prequalify for credit for page', 'short finance application form', 'srp financing form', 'vdp financing form' ]
		},
		{
			mappedType: 'General Contact',
			forms: [ 'contact employee', 'contact us', 'email review', 'general contact', 'unsubscribe' ]
		},
		{
			mappedType: 'Get a Quote',
			forms: [ 'eprice form', 'vdp eprice form' ]
		},
		{
			mappedType: 'Make an Offer',
			forms: [ 'make an offer form', 'reserve now', 'reserve vehicle', 'reserve vehicle join waitlist', 'vdp make an offer form' ]
		},
		{
			mappedType: 'Parts',
			forms: [ 'order a part', 'order a part boats', 'order a part farm', 'order a part heavy equipment', 'order a part heavy truck', 'order a part moto atv snow watercraft', 'order a part rv', 'order a part trailer' ]
		},
		{
			mappedType: 'Payment Estimator',
			forms: [ 'personalized payments' ]
		},
		{
			mappedType: 'Request More Info',
			forms: [ 'request carfax canada', 'showroom request more info', 'student discount', 'vdp inquire further' ]
		},
		{
			mappedType: 'Schedule Service',
			forms: [ 'auto detailing form', 'body shop collision center form', 'book service', 'book service boats', 'book service farm', 'book service heavy equipment', 'book service heavy truck', 'book service moto atv snow watercraft', 'book service rv', 'book service trailer' ]
		},
		{
			mappedType: 'Service',
			forms: [ 'ask a technician', 'extended car warranty', 'recall check' ]
		},
		{
			mappedType: 'Specials',
			forms: [ 'reserve this special' ]
		},
		{
			mappedType: 'Test Drive',
			forms: [ 'book a sales appointment', 'book a test drive', 'test drive from home form' ]
		},
		{
			mappedType: 'Trade In',
			forms: [ 'sell us your vehicle', 'trade in appraisal', 'trade in form', 'trade in form boat', 'trade in form equipment', 'trade in form farm', 'trade in form heavy truck', 'trade in form moto atv snow', 'trade in form rv', 'trade in form trailer' ]
		},
		{
			mappedType: 'Vehicle Finder',
			forms: [ 'carfinder', 'carfinder boat', 'carfinder farm', 'carfinder heavy equipment', 'carfinder heavy truck', 'carfinder moto atv snow watercraft', 'carfinder rv', 'carfinder trailer', 'carfinder trade in form' ]
		}
	];


	typeMap.forEach( function( type ) {
		if ( -1 !== type.forms.indexOf( currentForm ) ) {
			newType = type.mappedType;
		}
	});
	return newType;
}

function getLeadType( currentForm ) {
	let handraisers  = [ 'email a friend form', 'vdp email a friend form', 'special email a friend' ];
	let leadType = 'lead';
	if ( -1 !== handraisers.indexOf( currentForm ) ) {
		leadType = 'handraiser';
	}
	return leadType;
}

module.exports = shiftDigital;

const shiftDigital = require( 'AchillesJs/datalayer-specific/shift-digital' );
const utilities = require( './utilities' );

window.lastPushedAnalytics = {
	data: {},
	time: 0
};

let dataLayerManager = {
	fetchAnalyticsData( element, event = {}) {
		let attrs = element.attributes;
		let data = {};
		let consider = false;
		let name = '';
		let globalKey = '';
		for ( let value of attrs ) {
			consider = value.nodeName.startsWith( 'data-anl-' );
			if ( consider ) {

				// If asking to be filled by a global value, use a switch/case condition to fill it up.
				if ( value.nodeName.startsWith( 'data-anl-glb-' ) ) {
					name = value.nodeValue;
					globalKey = value.nodeName.replace( 'data-anl-glb-', '' );
					switch ( globalKey ) {
					case 'page_type':
						data[name] = pageType || '';
						break;
					case 'page_location':
						data[name] = window.location;
						break;
					case 'language':
						data[name] = globalVars.language;
						break;
					case 'element_color':
						data[name] = utilities.rgbToHex( getComputedStyle( element ).backgroundColor );
						break;
					case 'event_type':
						data[name] = event.type || '';
						break;
					case 'url_param_key':
						const key = element.getAttribute( 'data-anl-glb-url_param_key' );
						const params = new URLSearchParams( window.location.search );
						const paramValue = params.get( key );
						const state = paramValue ? 'active' : 'cleared';
						data['element_value'] = paramValue;
						data['element_state'] = state;
						break;
					default:
						break;
					}
				} else {
					name = value.nodeName.replace( 'data-anl-', '' );
					data[name] = value.nodeValue;
				}

			}
		}

		return data;
	},
	pushAnalytics( event, data = {}) {
		let element = event.target;
		if ( element ) {
			data = {...dataLayerManager.fetchAnalyticsData( element, event ), ...data};
		}

		if ( data.hasOwnProperty( 'event' ) ) {
			let now = Date.now();

			// Let's make sure we do not push duplicated data due to quick double clicks or parent/child same action.
			const clonedData = { ...data };
			if ( clonedData.hasOwnProperty( 'gtm.uniqueEventId' ) ) {
				delete clonedData['gtm.uniqueEventId'];
			}
			if ( 300 < now - window.lastPushedAnalytics.time || JSON.stringify( clonedData ) !== JSON.stringify( window.lastPushedAnalytics.data ) ) {
				dataLayer.push( data );
				window.lastPushedAnalytics.data = clonedData;
				window.lastPushedAnalytics.time = now;
			}
		}
	},
	loadShiftDigitalPageView() {

	},

	// Store adp_foureyes_id into dataLayer.
	loadUniteDigitalPageView( session ) {
		dataLayer.push({
			udSessionId: session
		});
	},
	trackMediaGalleryOpen() {
		dataLayer.push({
			'event': 'e_mediaGalleryOpen'
		});
	},
	trackMediaView( type, position ) {
		let data = {
			'event': 'e_mediaView',
			'mediaType': type
		};
		if ( position ) {
			data.mediaPosition = position;
		}
		dataLayer.push( data );
	},
	trackVideoView() {
		dataLayer.push({
			'event': 'e_videoPlay'
		});
	},
	trackCarfaxView() {
		dataLayer.push({
			'event': 'e_carfaxView'
		});
	},
	trackMyGarageRegister() {
		dataLayer.push({
			'event': 'e_register'
		});
	},
	trackMyGarageLogin() {
		dataLayer.push({
			'event': 'e_login'
		});
	},
	trackVdp( vehicle ) {
		dataLayer.push({
			vehicle
		});
	},
	trackShowroomModel( make, model, year ) {
		dataLayer.push({
			'vehicle': {
				'make': make,
				'model': model,
				'year': year,
				'showroom': 'true'
			}
		});
	},
	trackVehicleForm( vehicle ) {
		dataLayer.push({
			'event': 'e_srpVehicleForm_open',
			vehicle
		});
	},
	trackQuickViewOpen( vehicle ) {
		dataLayer.push({
			'event': 'e_quickView',
			'vdpSource': 'quickView',
			'vehicle': {
				'make': vehicle.make,
				'model': vehicle.model,
				'year': vehicle.year,
				'condition': 1 === vehicle.certified ? 'CPO' : vehicle.sale_class,
				'id': vehicle.ad_id,
				'bodyStyle': vehicle.body_style,
				'odometer': vehicle.odometer,
				'stockNumber': vehicle.stock_number,
				'vin': vehicle.vin,
				'colour': vehicle.exterior_color,
				'msrp': vehicle.initial_price,
				'internetPrice': vehicle.final_price,
				'trim': vehicle.trim,
				'status': '',
				'transmission': vehicle.transmission,
				'cpo': vehicle.certified,
				'kilometers': vehicle.fuel_economy_city_km,
				'engine': vehicle.engine,
				'driveTrain': vehicle.drive_train,
				'doors': vehicle.doors,
				'fuelType': vehicle.fuel_type,
				'vehicleClass': vehicle.vehicle_class,
				'dateOnLot': vehicle.date_on_lot,
				'showroom': 'false'
			}
		});
	},
	trackQuickViewClose() {
		dataLayer.push({
			'event': 'e_quickView_close',
			'vdpSource': 'srp',
			'vehicle': undefined
		});
	},
	trackSearchResults( filters ) {
		if ( ! filters.firstLoad ) {
			delete filters.firstLoad;
			const obj = {
				'event': 'e_searchResults',
				...filters
			};
			dataLayer.push( obj );
		}
	},

	// Mitsubishi Tierless Analytics - DataLayer Specifications - search
	trackSearchForMitsubishi( filters ) {
		dataLayer.push({
			'event': 'search',
			'search_keyword': filters.search.keyword,
			'results_total': filters.search.numberResults
		});

	},

	// Mitsubishi Tierless Analytics - DataLayer Specifications - view_showroom
	trackShowroomForMitsubishi() {
		dataLayer.push({
			'event': 'view_showroom'
		});
	},

	// Mitsubishi Tierless Analytics - DataLayer Specifications - vlp_page & vdp_page
	trackPageForMitsubishi( model, pageType ) {
		let categoryCode = '';
		let vehicleModel = model.toLowerCase();

		// Get vehicle category code.
		switch ( vehicleModel ) {
		case 'outlander':
		case 'outlander phev':
		case 'eclipse cross':
			categoryCode = 'Cat-C';
			break;
		case 'rvr':
			categoryCode = 'Cat-B';
			break;
		case 'mirage':
			categoryCode = 'Cat-A';
			break;
		}
		const eventType = ( 'vlp' === pageType ) ? 'vlp_page' : ( 'vdp' === pageType ) ? 'vdp_page' : null;
		if ( eventType ) {
			dataLayer.push({
				'event': eventType,
				'nameplate': vehicleModel,
				'vehicle_category_code': categoryCode
			});
		}
	},
	trackVdpCta( type ) {
		let eventData = {
			'event': 'e_vdpCtaClick',
			'vdpCtaClick': {
				'buttonText': type
			}
		};
		eventData = utilities.getABTestContactUsValue( eventData );
		dataLayer.push( eventData );
	},
	trackFormShown( data ) {},
	trackFormStart( data ) {
		dataLayer.push({
			'event': 'e_contactFormStart',
			'contactForm': data
		});
	},
	trackFormSent( obj ) {
		dataLayer.push( obj );
	},
	trackFormExit( data ) {
		dataLayer.push({
			'event': 'e_contactFormExit',
			'contactForm': data
		});
	},
	trackSrpFormExit() {
		dataLayer.push({
			'event': 'e_srpVehicleForm_close',
			'vehicle': undefined
		});
	},

	getVinlessCardDetails( vinlessCard ) {
		const vinLessType = vinlessCard.displayCriteria.toLowerCase().includes( 'marketing' ) ? 'Marketing Card' : 'No Inventory Card';

		return {
			titleFr: vinlessCard.options.overlayTitle.frValue,
			titleEn: vinlessCard.options.overlayTitle.enValue,
			descriptionFr: vinlessCard.options.overlayDescription.frValue,
			descriptionEn: vinlessCard.options.overlayDescription.enValue,
			ctaLabelFr: vinlessCard.options.ctaLabel.frValue,
			ctaLabelEn: vinlessCard.options.ctaLabel.enValue,
			img: vinlessCard.img,
			criteria: vinlessCard.displayCriteria,
			vinLessType: vinLessType,
			position: vinlessCard.position,
			theme: vinlessCard.theme
		};
	},

	trackVinlessCardsView( vinlessCard ) {
		dataLayer.push({
			event: 'e_vinlessCardsView',
			vinlessCard
		});
	},

	trackVinlessCardClick( vinlessCard ) {
		dataLayer.push({
			event: 'e_vinlessCardsClick',
			vinlessCard
		});
	},

	trackCalcInteract( calc ) {
		const obj = {
			'event': 'e_calcInteraction',
			calc
		};
		dataLayer.push( obj );
	},
	trackCalcSuccess() {
		dataLayer.push({
			'event': 'e_calcSuccess'
		});
	},
	trackSliderImpression( slide ) {
		dataLayer.push({
			'event': 'e_sliderImpression',
			'slider': slide
		});
	},
	trackSliderClick( slide ) {
		dataLayer.push({
			'event': 'e_sliderClick',
			'slider': slide
		});
	},
	trackDealershipSettings( domain ) {
		dataLayer.push({
			'networkDomain': domain
		});
	},
	trackTypedSearchEvent( keyword ) {

	},
	trackOffer( offerInfo ) {

	},
	trackClickToCallDepartment( department ) {

	},
	trackGetDirections() {

	},
	trackServiceOffer( special, category ) {

	},
	trackClickToCallDepartmentGA4( phoneNumber, department ) {
		const isSwapable = '8' === phoneNumber[2];
		const trffkScript = document.getElementById( 'convertus-trffk-assets' );

		let affiliation = null;

		//call rail is affiliation if number starts with an 8 and they are a TRFFK customer, call source is affiliation is starts with 8 and NOT TRFFK customer
		if ( isSwapable && trffkScript ) {
			affiliation = 'call rail';
		} else if ( isSwapable && ! trffkScript ) {
			affiliation = 'call source';
		}

		dataLayer.push({
			'event': 'click_to_call',
			'comm_phone_number': phoneNumber,
			'department': department,
			'affiliation': affiliation
		});
	},
	trackSrpSelectFilters( event ) {
		const elementColor = getComputedStyle( event.target ).backgroundColor;
		const elementColorHex = utilities.rgbToHex( elementColor );
		const elementPosition = utilities.getElementPosition( event.target );
		const order = Array.from( document.querySelectorAll( '.extra-filters [convertus-data-id="srp__sort-vehicles-select"], .extra-filters [convertus-data-id="srp__list-view-select"]' ) ).indexOf( event.target );
		let value;

		try {
			value = JSON.parse( event.target.value  );
		} catch ( e ) {
			value = event.target.value;
		}

		dataLayer.push({
			'event': 'element_configuration',
			'event_action_result': 'search',
			'event_action': event.type,
			'element_state': '' === event.target.value.trim() ? 'cleared' : 'active',
			'element_type': 'item list filter',
			'element_subtype': event.srcElement.type,
			'element_title': event.target.attributes[0].value.split( '__' )[1],
			'element_value': value,
			'element_color': elementColorHex,
			'element_position': elementPosition,
			'element_order': order + 1
		});
	},
	trackSrpMultiSelectFilters(
		event,
		placeholder,
		filterKey,
		callback = {
			parentSelector: null,
			childSelector: null,
			value: null,
			state: null
		}) {

		const parentSelector = callback.parentSelector ? callback.parentSelector : '.multiple-select';
		const childSelector = callback.childSelector ? callback.childSelector : '.select.multiple-select__select';

		// using setTimeout so search params are added to URL first, easier to grab values this way as these are custom inputs
		setTimeout( function() {
			const params = new URLSearchParams( window.location.search );
			const value = callback.value ? callback.value : params.get( filterKey );
			const state = callback.state ? callback.state : value ? 'active' : 'cleared';
			const elementColor = getComputedStyle( event.target.closest( parentSelector ).querySelector( childSelector ) ).backgroundColor;
			const elementColorHex = utilities.rgbToHex( elementColor );
			const elementPosition = utilities.getElementPosition( event.target.closest( parentSelector ) );
			const order = Array.from( document.querySelectorAll( parentSelector ) ).indexOf( event.target.closest( parentSelector ) );

			dataLayer.push({
				'event': 'element_configuration',
				'event_action_result': 'search',
				'event_action': event.type,
				'element_state': state,
				'element_type': 'item list filter',
				'element_subtype': 'Mutli-Select',
				'element_title': placeholder.toLowerCase(),
				'element_value': value,
				'element_color': elementColorHex,
				'element_position': elementPosition,
				'element_order': order + 1
			});
		}, 300 );
	},
	trackSrpRadioFilter(
		event,
		order = null,
		callback = {
			elementTitle: null,
			elementValue: null,
			elementState: null
		}) {
		const elementColor = getComputedStyle( event.target ).color;
		const elementColorHex = utilities.rgbToHex( elementColor );
		const elementPosition = utilities.getElementPosition( event.target );

		dataLayer.push({
			'event': 'element_configuration',
			'event_action_result': 'search',
			'event_action': event.type,
			'element_type': 'item list filter',
			'element_title': callback.elementTitle ? callback.elementTitle : 'sale-class',
			'element_subtype': event.srcElement.type,
			'element_state': callback.elementState ? callback.elementState : event.srcElement.checked ? 'active' : 'cleared',
			'element_value': callback.elementValue ? callback.elementValue : event.target.value,
			'element_color': elementColorHex,
			'element_position': elementPosition,
			'element_order': order + 1
		});
	},
	trackSrpSearchFilters: utilities.debounce( event => {
		const elementColor = getComputedStyle( event.target ).backgroundColor;
		const elementColorHex = utilities.rgbToHex( elementColor );
		const elementPosition = utilities.getElementPosition( event.target );

		dataLayer.push({
			'event': 'element_configuration',
			'event_action_result': 'search',
			'event_action': 'keypress',
			'element_state': '' === event.target.value.trim() ? 'cleared' : 'active',
			'element_type': 'item list filter',
			'element_subtype': event.target.tagName.toLowerCase(),
			'element_title': 'srp-search',
			'element_value': event.target.value,
			'element_color': elementColorHex,
			'element_position': elementPosition,
			'element_order': 1 //static order as there is only 1
		});
	}, 1000 ),
	trackSrpSliders(
		values,
		component,
		title,
		range,
		filterKey,
		callback = {
			values: null,
			elementColor: null
		}) {

		const params = new URLSearchParams( window.location.search );
		const currentValue = callback.values ? callback.values : params.get( filterKey );
		const eventValues = Object.values( values );

		if ( currentValue === eventValues.join( ',' ) ) {
			return;
		}

		const elementColor = callback.elementColor ? callback.elementColor : getComputedStyle( component.$el.querySelector( '.vue-slider-process' ) ).backgroundColor;
		const elementColorHex = utilities.rgbToHex( elementColor );
		const elementPosition = utilities.getElementPosition( component.$el );
		const order = Array.from( document.querySelectorAll( '.vue-slider' ) ).indexOf( component.$el.querySelector( '.vue-slider' ) );

		dataLayerManager.pushAnalytics({}, {
			'event': 'element_configuration',
			'event_action_result': 'search',
			'event_action': 'input',
			'element_state': range[0] === eventValues[0] && range[range.length - 1] === eventValues[eventValues.length - 1] ? 'cleared' : 'active',
			'element_type': 'item list filter',
			'element_subtype': 'slider',
			'element_title': `${title.toLowerCase()}-slider`,
			'element_value': eventValues,
			'element_color': elementColorHex,
			'element_position': elementPosition,
			'element_order': order + 1
		});
	},
	trackResetFilterButton( event ) {
		const elementColor = getComputedStyle( event.target ).backgroundColor;
		const elementColorHex = utilities.rgbToHex( elementColor );
		const elementPosition = utilities.getElementPosition( event.target );

		dataLayer.push({
			'event': 'element_configuration',
			'event_action_result': 'search',
			'event_action': event.type,
			'element_state': null,
			'element_type': 'item list filter',
			'element_subtype': 'button',
			'element_title': event.target.innerText,
			'element_value': event.target.innerText,
			'element_color': elementColorHex,
			'element_position': elementPosition,
			'element_order': 1
		});
	},
	trackPaidMediaErrorPage( type, listingUrl, landingUrl ) {
		dataLayer.push({
			'event': 'paidmedia_error_page',
			'paidmedia_error_page': {
				'status': true,
				'type': type,
				'listing_url': listingUrl,
				'landing_url': landingUrl
			}
		});
	}
};

$( window ).on( 'load', function() {
	if ( '1' === globalVars.hasSDA && 'undefined' !== typeof sd && 'undefined' !== typeof shiftDigital ) {
		const funcs = Object.getOwnPropertyNames( shiftDigital );

		funcs.forEach( func => {
			const old = dataLayerManager[func];

			// Append shift digital tracking to existing tracking.
			dataLayerManager[func] = ( args ) => {
				old.call( this, args );
				shiftDigital[func]( args );
			};
		});
	}
});

module.exports = dataLayerManager;

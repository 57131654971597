export default function() {
	const phoneMobileElements = document.querySelectorAll( 'input.dealer-settings__phone--mobile-check' );

	for ( let i = 0; i < phoneMobileElements.length; i++ ) {

		// Toggle mobile elements on load.
		toggleMobilePhone( phoneMobileElements[i]);

		// Add event listener for checkbox click.
		phoneMobileElements[i].addEventListener( 'click', function() {
			toggleMobilePhone( phoneMobileElements[i]);
		});
	}

	function toggleMobilePhone( phoneElement ) {
		if ( true === phoneElement.checked ) {
			const mobileElement = document.querySelector( '.' + phoneElement.name + '_mobile' );

			mobileElement.style.visibility = 'visible';
		} else {
			const mobileElement = document.querySelector( '.' + phoneElement.name + '_mobile' );

			mobileElement.style.visibility = 'collapse';
		}
	};
}
